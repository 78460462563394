import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'name',
      label: 'model.name'
    },
    {
      prop: 'order',
      label: 'category.order'
    },
    {
      prop: 'brandType',
      label: 'terminal.brand',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'brand_type', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'appType',
      label: 'file.application-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'app_type')
      }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '300',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
