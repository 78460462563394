import { getCommonParamsOptions } from '@/utils/common.js'

export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'model.name'
    },
    {
      field: 'brandType',
      type: 'select',
      label: 'terminal.brand',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getCommonParamsOptions('brand_type', {
          isNeedPreFix: false
        })
    },
    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type')
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
    // {
    //   field: 'order',
    //   type: 'input',
    //   label: 'category.order'
    // }
  ]
}
